<template>
  <BaseDrawer position="right" size-class="w-[36rem]" class="text-white" :overlay="false" @update:model-value="hide">
    <div class="flex flex-col" v-if="coinData.coin">
      <a
        v-bind="{ ...(!signalNews(newsItem) && { href: newsItem?.link }) }"
        target="_blank"
        class="flex flex-col space-y-0.5 bg-blue-900/25 px-4 py-2 text-xs font-medium text-blue-100"
        v-if="newsItem"
      >
        <span class="text-xs opacity-50">
          {{ minimalTimeWithinToday(newsItem.timestamp) }}
          <span v-if="newsItem.sources"> - {{ newsItem.sources.map(s => s.name)[0] }} </span>
        </span>
        <div class="flex items-center">
          <p class="line-clamp-2" v-html="newsItem.translated_headline || newsItem.headline"></p>
        </div>
      </a>
      <div class="mt-4 flex flex-row items-center justify-between px-2">
        <div class="flex space-x-1">
          <CoinIcon :coin="coinData.coin_uid" class="h-6 w-6" />
          <div>
            <span @click="onCoinClick()" class="coin-name">
              <div class="cursor-pointer text-lg font-medium leading-none">
                {{ coinData.coin.name }}
              </div>
            </span>
            <div v-if="newsItem" class="mt-0.5 leading-none">
              <div class="ml-auto flex items-center space-x-2">
                <span class="text-xs font-medium text-secondary">Since Article</span>
                <div
                  v-for="tf in timeframes"
                  :key="tf.id"
                  :disabled="tf.disable ? true : false"
                  @click="handleAction(tf.id, tf.disable)"
                  class="rounded px-2 py-0.5 text-xs font-medium"
                  :class="`${
                    tf.id == selectedTimeframe && !tf.disable
                      ? 'bg-blue-900 text-blue-100'
                      : 'bg-gray-800 text-gray-400 duration-100'
                  } ${
                    tf.disable
                      ? 'cursor-not-allowed bg-gray-700'
                      : 'cursor-pointer hover:bg-blue-900 hover:text-blue-100'
                  }`"
                >
                  {{ tf.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-28 space-y-1 pr-4 text-left">
          <div class="text-lg font-medium leading-none text-white">
            <span :class="coinData.ticker + '_price'">${{ formatPrice(coinData.price) }}</span>
          </div>
          <div class="flex items-center" v-if="priceChange">
            <div class="inline text-xs font-medium leading-none">
              <span class="mr-1 text-gray-400">
                {{ !newsItem || !selectedTimeframe ? 'SA' : selectedTimeframe }}
              </span>
            </div>
            <BasePercent :percent="priceChange * 100" class="font-medium" />
          </div>
        </div>
      </div>
      <div class="py-3">
        <div class="w-100 flex flex-row px-4">
          <div class="flex-1 whitespace-nowrap px-3 text-center">
            <div class="text-xs font-medium text-secondary">Tweets (24hr)</div>
            <div class="text-xl font-medium">
              <span v-if="noSmaAlias"></span>
              <span v-else> {{ formatNumber(coinData.tweet_volume) }}</span>
            </div>
            <span
              class="relative inline-flex items-end text-xs font-medium text-gray-400"
              v-if="tweetsChange && !noSmaAlias"
            >
              <BasePercent :percent="tweetsChange * 100" class="font-medium" />
              <BaseTooltip>
                <template #target>
                  <span class="ml-0.5 mt-0.5 flex flex-col justify-center">
                    {{ !newsItem || !selectedTimeframe ? ' SA' : selectedTimeframe }}
                  </span>
                </template>
                <template #default>{{ getTooltipContent() }}</template>
              </BaseTooltip>
            </span>
          </div>
          <div class="flex-1 whitespace-nowrap px-3 text-center">
            <div class="text-xs font-medium text-secondary">Volume (24hr)</div>
            <div class="text-xl font-medium" v-if="coinData.trading_volume">
              ${{ formatNumber(coinData.trading_volume) }}
            </div>
            <span class="inline-flex items-end text-xs font-medium text-gray-400" v-if="volumeChange">
              <BasePercent :percent="volumeChange * 100" class="font-medium" />
              <BaseTooltip>
                <template #target>
                  <span class="ml-0.5 mt-0.5 flex flex-col justify-center">
                    {{ !newsItem || !selectedTimeframe ? ' SA' : selectedTimeframe }}
                  </span>
                </template>
                <template #default>{{ getTooltipContent() }}</template>
              </BaseTooltip>
            </span>
          </div>
          <div class="flex-1 whitespace-nowrap px-3 text-center">
            <div class="text-xs font-medium text-secondary">Daily Sentiment</div>
            <div class="text-xl font-medium">
              <span v-if="noSmaAlias"></span>
              <SentimentScoreWithBar
                :sentiment="coinData.daily_sentiment"
                text-align="justify-center"
                icon-size="w-6 h-2"
                v-else
              />
            </div>
            <span class="inline-flex items-end text-xs font-medium text-gray-400" v-if="sentimentChange && !noSmaAlias">
              <BasePercent :percent="sentimentChange * 100" class="font-medium" />
              <BaseTooltip>
                <template #target>
                  <span class="ml-0.5 mt-0.5 flex flex-col justify-center">
                    {{ !newsItem || !selectedTimeframe ? ' SA' : selectedTimeframe }}
                  </span>
                </template>
                <template #default>{{ getTooltipContent() }}</template>
              </BaseTooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
    <NoDataFound class="pb-2 pt-4" v-else />
    <BaseCard class="m-2 h-48 max-h-64" :collapsable="true">
      <template #header>
        {{ `${noSmaAlias ? 'Price' : 'Tweet Volume vs. Price vs. Sentiment'}  ${newsItem ? 'vs. Event' : ''}` }}
      </template>
      <ChartPriceMktSentiment :series="filteredChartData" :loading="isPriceChartLoading" />
    </BaseCard>
    <BaseTabs v-model="tab" :tabs="tabs" class="overflow-auto">
      <TabPanel class="overflow-auto">
        <TableNewsSimple :data="coinNews" :paginate="getCoinNews" class="w-100 pb-10" />
      </TabPanel>
      <TabPanel class="overflow-auto">
        <TableNewsSimple :data="similarNews" :paginate="getSimilarNews" class="w-100 pb-10" />
      </TabPanel>
    </BaseTabs>
  </BaseDrawer>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import ChartPriceMktSentiment from '@/components/chart/ChartPriceMktSentiment.vue';
import TableNewsSimple from '@/components/table/TableNewsSimple.vue';
import { router } from '@inertiajs/vue3';
import useHttp from '@/composeables/http';
import { useStore } from 'vuex';
import { minimalTimeWithinToday, formatNumber, formatPrice } from '@/composeables/filters';
import { signalNews } from '@/composeables/news';
import { DateTime } from 'luxon';
import SentimentScoreWithBar from '@/components/SentimentScoreWithBar.vue';

const $store = useStore();
const $http = useHttp();

const props = defineProps({
  coin: { type: String, default: 'ethereum' },
  newsItem: { type: Object, default: () => {} },
  socialNews: { type: Boolean, default: false }
});

// COIN NEWS
const coinNews = ref([]);
async function getCoinNews(pageParams = { page: 1, perPage: 20 }) {
  if (props.coin != '') {
    let response = await $http.get(`/data/coin_news`, {
      params: { coin_uid: props.coin, page: pageParams.page, limit: pageParams.perPage }
    });
    coinNews.value.push(...response.data.data);
  }
  return pageParams;
}

// TAB
const tab = ref('recent');
const tabValues = ref([
  { id: 'recent', label: 'Recent News' },
  { id: 'similar', label: 'Similar News' }
]);
const tabs = ref([]);

// NEWS ITEM
watch(
  () => props.newsItem,
  val => {
    if (val) {
      selectedTimeframe.value = null;
      checkDataAvailable();
      loadSideBar();
    }
    checkToShowSimilarNews();
  }
);
const newsItemId = computed(() => {
  return props.newsItem ? props.newsItem.id : null;
});

// SIMILAR NEWS
const similarNews = ref([]);
onMounted(() => {
  checkToShowSimilarNews();
});
async function getSimilarNews(pageParams = { page: 1, perPage: 20 }) {
  if (newsItemId.value > 0 && !props.socialNews) {
    let response = await $http.get('/data/similar_news', {
      params: {
        news_item_id: newsItemId.value,
        page: pageParams.page,
        limit: pageParams.perPage
      }
    });
    if (pageParams.page == 1) {
      similarNews.value = response.data.data;
    } else {
      similarNews.value.push(...response.data.data);
    }
  }
  return pageParams;
}

function checkToShowSimilarNews() {
  if (!props.newsItem || props.socialNews) {
    tabs.value = tabValues.value.filter(r => {
      return r.id !== 'similar';
    });
    tab.value = 'recent';
  } else {
    tabs.value = tabValues.value;
  }
}

// CHART LOADING
const isPriceChartLoading = ref(true);

// TIMEFRAME
const timeframes = ref([
  {
    id: '1D',
    text: '1d',
    disable: true,
    tooltip: '1 Day'
  },
  {
    id: '3D',
    text: '3d',
    disable: true,
    tooltip: '3 Days'
  },
  {
    id: '7D',
    text: '7d',
    disable: true,
    tooltip: '7 Days'
  }
]);
const selectedTimeframe = ref(null);

// COIN DATA
const coinData = computed(() => {
  return $store.getters.coins.find(x => x.coin_uid === props.coin) || {};
});
const coinSinceData = ref('');
const coinDataArticleTime = ref('');

function getCoinData(timeframe) {
  const timeStamp = props.newsItem ? props.newsItem.timestamp : '';
  coinSinceData.value = '';
  coinDataArticleTime.value = '';
  if (props.coin != '') {
    $http
      .get(`/data/since_article`, {
        params: {
          coin_uid: props.coin,
          since: timeStamp,
          to: timeframe
        }
      })
      .then(response => {
        // eslint-disable-next-line chai-friendly/no-unused-expressions
        timeframe ? (coinSinceData.value = response.data) : (coinDataArticleTime.value = response.data);
      });
  }
}
function getCoinDataAll() {
  getCoinData(selectedTimeframe.value ? selectedTimeframe.value : '1D');
  getCoinData(null);
}

// NO SMA ALIAS
const noSmaAlias = computed(() => {
  return !coinData.value.tweet_volume || coinData.value.tweet_volume < 12;
});

// CHART DATA
const chartData = ref({});
const filteredChartData = computed(() => {
  return noSmaAlias.value ? { price: chartData.value.price, event: chartData.value.event } : chartData.value;
});

async function getChartData() {
  isPriceChartLoading.value = true;
  chartData.value = {};
  const timeStamp = props.newsItem ? props.newsItem.timestamp : '';
  try {
    const resp = await $http.get(`/data/price_sentiment_chart`, {
      params: {
        coin_uid: props.coin,
        newstime: timeStamp,
        to: selectedTimeframe.value ? selectedTimeframe.value : '1D'
      }
    });
    setTimeout(() => {
      chartData.value = resp.data;
      isPriceChartLoading.value = false;
    }, 1500);
  } catch (error) {
    chartData.value = {};
    isPriceChartLoading.value = false;
  }
}

// DATA AVAILABLE
function checkDataAvailable() {
  timeframes.value.forEach(i => {
    i.disable = true;
  });
  const timestamp = props.newsItem ? props.newsItem.timestamp : null;
  if (timestamp) {
    const diff = getDaysBetweenDate(timestamp);
    if (diff > 1) {
      selectedTimeframe.value = '1D';
      timeframes.value[0].disable = false;
    }
    if (diff > 3) {
      timeframes.value[1].disable = false;
    }
    if (diff > 7) {
      timeframes.value[2].disable = false;
    }
  }
}
onMounted(() => {
  checkDataAvailable();
});

// LOAD SIDEBAR
function loadSideBar() {
  getCoinNews();
  getSimilarNews();
  getChartData();
  getCoinDataAll();
}
onMounted(() => {
  loadSideBar();
});

// COIN
watch(
  () => props.coin,
  val => {
    if (val) {
      coinNews.value = [];
      similarNews.value = [];
      checkDataAvailable();
      loadSideBar();
    }

    checkToShowSimilarNews();
  }
);

// SOCIAL NEWS
watch(
  () => props.socialNews,
  () => {
    checkToShowSimilarNews();
  }
);

// TWEETS CHANGE
const tweetsChange = computed(() => {
  return coinSinceData.value.tweet_volume / coinDataArticleTime.value.tweet_volume - 1;
});

// VOLUME CHANGE
const volumeChange = computed(() => {
  return coinSinceData.value.trading_volume / coinDataArticleTime.value.trading_volume - 1;
});

// SENTIMENT CHANGE
const sentimentChange = computed(() => {
  return coinSinceData.value.daily_sentiment / coinDataArticleTime.value.daily_sentiment - 1;
});

// PRICE CHANGE
const priceChange = computed(() => {
  return coinSinceData.value.price / coinDataArticleTime.value.price - 1;
});

// SET TOOLTIP
function getTooltipContent() {
  return !selectedTimeframe.value
    ? 'Since Article'
    : timeframes.value.find(x => x.id == selectedTimeframe.value).tooltip;
}

// HIDE SIDEBAR
function hide() {
  $store.dispatch('resetCoinSidebar');
}
function getDaysBetweenDate(date) {
  const targetDate = DateTime.fromISO(date);
  const currentDate = DateTime.now();
  return currentDate.diff(targetDate, 'days').days;
}

// HANDLE TIMEFRAME CLICK
function handleAction(id, disable) {
  const timestamp = props.newsItem ? props.newsItem.timestamp : '';
  if (timestamp) {
    const diff = getDaysBetweenDate(timestamp);
    if (id == '1D' && diff > 1 && !disable) {
      selectedTimeframe.value = id;
      loadSideBar();
    } else if (id == '3D' && diff > 3 && !disable) {
      selectedTimeframe.value = id;
      loadSideBar();
    } else if (id == '7D' && diff > 7 && !disable) {
      selectedTimeframe.value = id;
      loadSideBar();
    }
  }
}

// COIN CLICK
function onCoinClick() {
  $store.dispatch('resetCoinSidebar');
  router.visit(`/coin/${coinData.value.coin_uid}`);
}
</script>
